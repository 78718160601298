
import { apiVersion } from './api-version';
import { endpoints } from './endpoints';

const releaseUrl = 'https://api.release.ats.uhigherdev.com';
const domain = 'release.ats.uhigherdev.com';

export const environment = {
  production: false,
  domain,
  mediaServerUrl: 'wcs.ats.uhigherdev.com',
  ...endpoints(releaseUrl, apiVersion)
};
